
import ProductoService from "@/Services/ProductoService";
export default {
  name: "ProductoDetalle",
  data() {
    return {
      breadcrumbs: [
        { etiqueta: "Artículos", to: { name: "productosGestor" } },
        { etiqueta: "Detalle" },
      ],
      articuloId: this.$route.params.id,
      opcionesToolbar: [],
      categorias: [],
      subcategorias: [],
      marcas: [],
      unidadesMedida: [],
      producto: {},
      editarProductoObj: {},
      modalEditar: false,
      modalEliminar: false,
      menuStatusOpciones: [
        {
          status: 200,
          statusNombre: "Activo",
          claseBackground: "status-bg-activo",
        },
        {
          status: 301,
          statusNombre: "Inactivo",
          claseBackground: "status-bg-inactivo",
        },
      ],
    };
  },
  mounted() {
    this.cargaInicial();
  },
  computed: {},
  methods: {
    async cargaInicial() {
      this.loader(true);
      await this.obtenerConcentradoOpciones(false);
      await this.obtenerDetalle(false);
      this.loader(false);
    },
    async obtenerDetalle(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        articuloId: this.articuloId,
      };
      await ProductoService.obtenerDetalle(datos)
        .then((resp) => {
          this.producto = resp.info;
          if (this.producto.categoria_id != null)
            this.seleccionarSubcategorias(this.producto.categoria_id);

          this.llenarOpcionesToolbar();
          if (loader) this.loader(false);
        })
        .catch((error) => {
          if (loader) this.loader(false);
          this.mostrarAlerta(error, "error");
        });
    },
    async obtenerConcentradoOpciones(loader = true) {
      if (loader) this.loader(true);
      await ProductoService.obtenerConcentradoOpciones()
        .then((resp) => {
          this.categorias = resp.categorias;
          this.marcas = resp.marcas;
          this.unidadesMedida = resp.unidadesMedida;
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    llenarOpcionesToolbar() {
      this.opcionesToolbar = [
        {
          texto: "Editar",
          icono: "editar",
          accion: "abrirModalEditar",
          disabled:
            this.producto.status == 300 ||
            !this.permisos.existePermiso(this.permisos.ARTICULOS_EDITAR),
        },
        {
          texto: "Eliminar",
          icono: "eliminar",
          accion: "abrirModalEliminar",
          disabled:
            this.producto.status == 300 ||
            !this.permisos.existePermiso(this.permisos.ARTICULOS_ELIMINAR),
        },
      ];
    },
    abrirModalEditar() {
      this.editarProductoObj = _.cloneDeep(this.producto);
      let existeCategoria = this.categorias.some(
        (c) => this.editarProductoObj.categoria_id == c.categoria_id
      );
      // Se valida si la cat/subcat se encuentra en listado de cat activas
      if (!existeCategoria) this.editarProductoObj.categoria_id = null;
      let existeSubCategoria = this.subcategorias.some(
        (sc) => this.editarProductoObj.subcategoria_id == sc.categoria_id
      );
      if (!existeSubCategoria) this.editarProductoObj.subcategoria_id = null;
      this.modalEditar = true;
    },
    cerrarModalEditar() {
      this.editarProductoObj = {};
      this.modalEditar = false;
    },
    async editarProducto(loader = true) {
      if (loader) this.loader(true);
      let producto = {
        articuloId: this.articuloId,
        titulo: this.editarProductoObj.titulo,
        categoriaId: this.editarProductoObj.categoria_id,
        subcategoriaId: this.editarProductoObj.subcategoria_id,
        marcaId: this.editarProductoObj.articulo_marca_id,
        unidadMedidaId: this.editarProductoObj.unidad_medida_id,
        valorEstimadoMinimo: this.editarProductoObj.valor_estimado_minimo,
        descripcion: this.editarProductoObj.descripcion,
      };
      return ProductoService.editarProducto(producto)
        .then(async (resp) => {
          this.cerrarModalEditar();
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => {
          this.mostrarAlerta(error, "error");
        })
        .finally(() => {
          if (loader) this.loader(false);
        });
    },
    async actualizarStatus(statusNuevo) {
      if (this.producto.status == statusNuevo) return;

      this.loader(true);
      let datos = {
        articuloId: this.articuloId,
        statusNuevo: statusNuevo,
      };
      await ProductoService.actualizarStatus(datos)
        .then(async (resp) => {
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
    abrirModalEliminar() {
      this.editarProductoObj.titulo = this.producto.titulo;
      this.modalEliminar = true;
    },
    cerrarModalEliminar() {
      this.editarProductoObj = {};
      this.modalEliminar = false;
    },
    async eliminarProducto(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        articuloId: this.articuloId,
      };
      return ProductoService.eliminarProducto(datos)
        .then(async (resp) => {
          this.cerrarModalEliminar();
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => {
          this.mostrarAlerta(error, "error");
        })
        .finally(() => {
          if (loader) this.loader(false);
        });
    },
    seleccionarSubcategorias(categoriaId) {
      this.editarProductoObj.subcategoria_id = null;
      let categoria = this.categorias.find(
        (cat) => cat.categoria_id == categoriaId
      );
      if (categoria) this.subcategorias = categoria.subcategorias;
    },
  },
};
